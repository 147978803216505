import create from 'zustand'

interface State {
  search: string
  searchCount: number
  setSearch: (x: string) => void
  increaseSearchCount: () => void
}

const useStore = create<State>((set) => ({
  search: '',
  searchCount: 0,
  setSearch: (input: string) => set(({ search: input })),
  increaseSearchCount: () => set((state: State) => ({ searchCount: state.searchCount + 1 })),
}))

export default useStore
