import axios from 'axios'

import ConditionsResponse from '../type/ConditionsResponse'

const apiClient = axios.create({
  baseURL: 'https://visual-crossing-weather.p.rapidapi.com/',
  headers: {
    'x-rapidapi-host': 'visual-crossing-weather.p.rapidapi.com',
    'x-rapidapi-key': '65814ad88cmsh002bd05f9b8b525p17c2a6jsnebab14f3dd50',
  },
})

const forecastWeather = async () => {
  const res = await apiClient.get<ConditionsResponse>('/forecast', { params: forecastParam })
  return res.data
}

const historicalWeather = async () => {
  const res = await apiClient.get<ConditionsResponse>('/history', { params: historicalParam })
  return res.data
}

// const optionsForecast: AxiosRequestConfig = {
//   method: 'GET',
//   url: 'https://visual-crossing-weather.p.rapidapi.com/forecast',
//   params: {
//     aggregateHours: '24',
//     location: 'Washington,DC,USA',
//     contentType: 'json', // json, csv
//     unitGroup: 'metric', // us, uk, metric
//     shortColumnNames: '0' // 0, 1
//   },
//   headers: {
//     'x-rapidapi-host': 'visual-crossing-weather.p.rapidapi.com',
//     'x-rapidapi-key': '65814ad88cmsh002bd05f9b8b525p17c2a6jsnebab14f3dd50'
//   }
// }

// const optionsHistoricalWeather = {
//   method: 'GET',
//   url: 'https://visual-crossing-weather.p.rapidapi.com/history',
//   params: {
//     startDateTime: '1979-01-01T00:00:00',
//     aggregateHours: '24',
//     location: 'Washington,DC,USA',
//     endDateTime: '1979-01-31T00:00:00',
//     unitGroup: 'metric',
//     dayStartTime: '8:00:00',
//     contentType: 'json',
//     dayEndTime: '17:00:00',
//     shortColumnNames: '0'
//   },
//   headers: {
//     'x-rapidapi-host': 'visual-crossing-weather.p.rapidapi.com',
//     'x-rapidapi-key': '65814ad88cmsh002bd05f9b8b525p17c2a6jsnebab14f3dd50'
//   }
// }

const forecastParam = {
  aggregateHours: '24',
  location: 'Washington,DC,USA',
  contentType: 'json', // json, csv
  unitGroup: 'metric', // us, uk, metric
  shortColumnNames: '0', // 0, 1
}

const historicalParam = {
  startDateTime: '1979-01-01T00:00:00',
  aggregateHours: '24',
  location: 'Washington,DC,USA',
  endDateTime: '1979-01-31T00:00:00',
  unitGroup: 'metric',
  dayStartTime: '8:00:00',
  contentType: 'json',
  dayEndTime: '17:00:00',
  shortColumnNames: '0',
}

export {
  apiClient,
  forecastWeather,
  historicalWeather,
}
