import { ReactElement } from 'react'

import { useDetectAdBlock } from 'adblock-detect-react'

interface Prop {
  children: ReactElement
}

const AdBlockWrapper = ({ children }: Prop): ReactElement => {
  const isAdBlockerInUse = useDetectAdBlock()

  if (isAdBlockerInUse) {
    window.alert('Ad Blocker detected')
  }

  // TODO: test and extend adblocker
  // * Custom wrapper works -> Fork repo and fix it there ?

  return (
    <div>
      {children}
    </div>
  )
}

export default AdBlockWrapper
