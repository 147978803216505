import { useEffect, useState } from 'react'

import { curry, debounce } from 'lodash-es'

const MOBILE_THRESHOLD = 576
const LARGE_TABLET_THRESHOLD = 991
const SMALL_TABLET_THRESHOLD = 767

const useDeviceSize = () => {
  const [width, setWidth] = useState(getWindowWidth())

  useEffect(() => {
    const resizeListener = debounce(() => {
      setWidth(getWindowWidth())
    }, 150)

    window.addEventListener('resize', resizeListener)

    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [])

  return {
    isSmallDevice: width <= MOBILE_THRESHOLD,
    isMediumDevice: width <= SMALL_TABLET_THRESHOLD,
    isLargeDevice: width <= LARGE_TABLET_THRESHOLD,
    customThreshold: customThreshold(width),
  }
}

const getWindowWidth = () =>
  window.innerWidth

const customThreshold = curry(
  (width: number, threshold: number) =>
    width < threshold
)

export default useDeviceSize
