import React from 'react'

import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import AdBlockWrapper from './components/ad-block-wrapper'
import HomeScreen from './components/home-screen'
import UnderConstructionScreen from './components/under-construction-screen'
import reportWebVitals from './reportWebVitals'

import './index.css'

const twentyFourHoursInMs = 1000 * 60 * 60 * 24

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: 1,
      staleTime: twentyFourHoursInMs,
    },
  },
})

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <AdBlockWrapper>
          <Routes>
            <Route path="/home" element= {<HomeScreen />} />
            <Route path="/" element= {<UnderConstructionScreen />} />
          </Routes>
        </AdBlockWrapper>
      </QueryClientProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
