import {
  ChangeEvent,
  ReactElement,
  SyntheticEvent,
  useRef,
} from 'react'

import classNames from 'classnames'
import { useQuery } from 'react-query'

import { forecastWeather } from '../../../common/api/get'
import useHover from '../../../common/hooks/useHover'
import useStore from '../../../common/store/main'
import ConditionsResponse from '../../../common/type/ConditionsResponse'

import './style.css'

const Search: React.FC = (): ReactElement => {
  const submitRef = useRef<HTMLInputElement>(null)
  const isHovered = useHover(submitRef)

  const state = useStore()

  const { refetch } = useQuery<ConditionsResponse>(
    'forecast',
    forecastWeather,
    { enabled: false }
  )

  const handleSubmit = (event: SyntheticEvent): void => {
    event.preventDefault()
    state.increaseSearchCount()
    refetch()
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void =>
    state.setSearch(event.target.value)

  return (
    <form
      className="search-form"
      onSubmit={(event) => handleSubmit(event)}
    >
      <input
        className='search-input'
        ref={submitRef}
        placeholder='Enter a Location, Zip Code or Coordinates'
        size={32}
        defaultValue={state.search}
        onChange={handleChange}
      />
      <input
        className={classNames('search-submit', { 'search-submit--hover': isHovered })}
        type='submit'
        value='Sub'
      />
    </form>
  )
}

export default Search
