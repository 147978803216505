import { ReactElement } from 'react'

import Search from '../framework/search'

import './style.css'

const HomeScreen: React.FC = (): ReactElement => (
  <div className="home-screen">
    <header />
    <main>
      <Search />
    </main>
    <footer />
  </div>
)

export default HomeScreen
