import { ChangeEvent, ReactElement, SyntheticEvent, useState } from 'react'

import { collection, addDoc } from '@firebase/firestore'
import classname from 'classnames'
import toast, { Toaster } from 'react-hot-toast'

import useDeviceSize from '../../common/hooks/useDeviceSize'
import { db } from '../../firebase-config'
import { ReactComponent as ChevronRight } from '../../style/assets/chevron_right.svg'

import './style.css'

const INFO = 'Compare the weather, at any location around the globe, between the current day and any point in time in the past 50 years and see how'

const UnderConstructionScreen: React.FC = (): ReactElement => {
  const [inputValue, setInputValue] = useState('')
  const { isSmallDevice } = useDeviceSize()
  const emailsCollectionRef = collection(db, 'emails')

  const handleInputValueChange = (event: ChangeEvent<HTMLInputElement>) =>
    setInputValue(event.target.value)

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault()
    createEmail()
    setInputValue('')
  }

  const createEmail = async () => {
    await addDoc(emailsCollectionRef, { address: inputValue })
      .then(() => {
        toast.success('Email submitted')
      })
      .catch((err) => {
        console.error(err)
        toast.error('Something went wrong!')
      })
  }

  return (
    <div className={
      classname(
        'under-construction-screen',
        { 'under-construction-screen--mobile': isSmallDevice }
      )
    }>
      <section>
        <div className="under-construction-screen__card" >
          <ul className="under-construction-screen__grid" >
            <li className="under-construction-screen__grid__header" >
              COMING SOON
            </li>
            <li className="under-construction-screen__grid__header" >
              Weather Comparing App
            </li>
            <li className="under-construction-screen__grid__info">
              <p>
                <span>{INFO}</span>
                <a href="https://en.wikipedia.org/wiki/Climate_change"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="under-construction-screen__grid__info__link" >
                  global warming</a> is affecting it.
              </p>
            </li>
            <li className="under-construction-screen__grid__tooltip" >
              Get notificated when website gets launched
            </li>
            <li >
              <form className="under-construction-screen__grid__wrapper"
                onSubmit={handleSubmit} >
                <label
                  htmlFor="email"
                  className={classname(
                    'under-construction-screen__grid__wrapper__label',
                    { 'under-construction-screen__grid__wrapper__label--has-text': inputValue.length > 0 }
                  )} >
                  Email address
                </label>
                <input
                  id="email"
                  className="under-construction-screen__grid__wrapper__input"
                  autoComplete="off"
                  value={inputValue}
                  onChange={handleInputValueChange}
                  spellCheck={false}
                  type="email" required
                  name="email"
                >
                </input>
                <button className="under-construction-screen__grid__wrapper__btn"
                  type="submit" >
                  <ChevronRight style={{ width: '40px', height: '40px' }} />
                </button>
              </form>
            </li>
          </ul>
        </div>
      </section>
      {!isSmallDevice && <div className="under-construction-screen__img" />}
      <Toaster toastOptions={{
        style: {
          border: '1px solid blue',
          padding: '16px',
          color: 'blue',
        },
      }} />
    </div>
  )
}

export default UnderConstructionScreen
